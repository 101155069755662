import { graphql, useStaticQuery } from 'gatsby';
import _has from 'lodash/has';
import React from 'react';
import JournalLink from './JournalLink';

import './journal-list.scss';

export default function JournalList() {
    const { journals } = useStaticQuery(
        graphql`
            query {
                journals: allMediaJournal(
                    filter: { status: { eq: true } }
                    sort: { fields: [created], order: [DESC] }
                    skip: 1
                ) {
                    edges {
                        node {
                            ...mediaJournalFragment
                        }
                    }
                }
            }
        `,
    );

    if (journals.edges.length < 1) {
        return <></>;
    }

    return (
        <div className="journal-list">
            <h2>Archive</h2>
            <ul>
                {journals.edges.map(({ node }, i) => {
                    if (_has(node, 'r.field_media_file.localFile.publicURL')) {
                        node.key = `journal-list-${i}`;
                        return (
                            <li key={node.key}><JournalLink journal={node} /></li>
                        );
                    }

                    return (<></>)
                })}
            </ul>
        </div>
    );
}
