import { graphql, useStaticQuery } from 'gatsby';
import _has from 'lodash/has';
import React from 'react';
import JournalLink from './JournalLink';

import './journal-list.scss';

export default function CurrentJournal() {
    const { currentJournal } = useStaticQuery(
        graphql`
            query {
                currentJournal: allMediaJournal(
                    filter: { status: { eq: true } }
                    sort: { fields: [created], order: [DESC] }
                    limit: 1
                ) {
                    edges {
                        node {
                            ...mediaJournalFragment
                        }
                    }
                }
            }
        `,
    );

    if (
        currentJournal.edges.length < 1 ||
        !_has(
            currentJournal.edges[0].node,
            'r.field_media_file.localFile.publicURL',
        )
    ) {
        return <></>;
    }

    const journal = currentJournal.edges[0].node;

    return (
        <div className="journal-current">
            <JournalLink journal={journal} />
        </div>
    );
}
