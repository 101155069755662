import React from 'react';

import ContentBreadcrumb from '../components/content/ContentBreadcrumb/ContentBreadcrumb';
import ContentHeader from '../components/content/ContentHeader/ContentHeader';
import CurrentJournal from '../components/content/Journal/CurrentJournal';
import JournalList from '../components/content/Journal/JournalList';
import JournalSignupForm from '../components/content/Journal/JournalSignupForm';
import Layout from '../components/layout/layout';
import Seo from '../components/layout/seo';

const JournalPage = ({ pageContext }) => {
    let {
        breadcrumb: { crumbs },
    } = pageContext;

    return (
        <Layout>
            <Seo title="European Valuer Journal" />
            <ContentBreadcrumb crumbs={crumbs} label="European Valuer Journal" />
            <ContentHeader
                title="European Valuer Journal"
                introduction="<p>European Valuer is the journal of the European valuation profession, in particular of the 70 000 qualified real estate, business and plant, machinery & equipment valuers belonging to TEGOVA’s member associations.</p>
                <p>It covers the impacts of EU law on real estate markets and sheds light on the confluence of valuation practice in an increasingly cohesive EU internal market extending to the EEA and the candidate EU member states.</p>
                <p>The revised Capital Requirements Regulation ensures the safety and security of the banking system by confirming the existing Regulation’s strict limitations on banks’ use of automated valuation models with zero or token valuer involvement. It also introduces a concept of “prudently conservative valuation criteria” to be applied in the process of determining value. European Valuer explores these key provisions.</p>
                <p>The European Green Deal is revolutionising the entire economy, nowhere more so than for the building sector, the top energy consumer and GHG emitter. Valuation must rapidly adapt to the market impacts of hard EU law mandating the building energy transformation. European Valuer explains these changes and investigates the impacts on valuation.</p>
                <p>European Valuer is the forum of expert debate on cutting edge valuation practice adapted to the new EU order.</p><p>&nbsp;</p>"
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <CurrentJournal />
                        <JournalSignupForm />
                        <div className="journal-contribute">
                            <p>
                                To contribute an article or to send a letter to
                                the editor commenting on one, contact us:
                                <br />
                                <span className="email-field">
                                    <i className="bi bi-envelope"></i>
                                    <a href="mailto:info@tegova.org">
                                        info@tegova.org
                                    </a>
                                </span>
                            </p>
                        </div>
                        <JournalList />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default JournalPage;
