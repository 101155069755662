import PropTypes from 'prop-types';
import _has from 'lodash/has';
import React from 'react';

const JournalLink = (props) => {
    const journal = props.journal;
    if (!_has(journal, 'r.field_media_file.localFile.publicURL')) {
        return <></>;
    }
    if (!_has(journal, 'r.field_media_mobile_file.localFile.publicURL')) {
        return (
            <>
            <h4>{journal.name}</h4>
            <a target="_blank"
                rel="noreferrer"
                href={journal.r.field_media_file.localFile.publicURL}
            >
                <span><i className="bi bi-file-earmark-arrow-down"></i> Download</span>
            </a>
        </>
        );
    }
    return (
        <>
            <h4>{journal.name}</h4>
            <a target="_blank"
                rel="noreferrer"
                href={journal.r.field_media_file.localFile.publicURL}
            >
                <span><i className="bi bi-file-earmark-arrow-down"></i> Desktop version</span>
            </a>
            <span className="separator"> / </span>
            <a target="_blank"
                rel="noreferrer"
                href={journal.r.field_media_mobile_file.localFile.publicURL}
            >
                <span><i className="bi bi-phone"></i> Mobile version</span>
            </a>
        </>
    );
};

JournalLink.propTypes = {
    journal: PropTypes.object,
};

export default JournalLink;
